/* global FWP */
/* global ajax_object */

// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  $('.main-home-slider').slick({
    cssEase: 'ease',
    fade: true,
    // arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 0,
    // slide: '.slick-slide',
  });
  $('.image-carousel-slides__slider').slick({
    cssEase: 'ease',
    fade: true,
    // arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 0,
    // slide: '.slick-slide',
  });

  $('.slider-dots__body').slick({
    cssEase: 'ease',
    fade: true,
    // arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // slide: '.slick-slide',
  });
// // Scroll to top Product section
  $(document).on('click', '.facetwp-checkbox', function (event) {
    event.preventDefault();
    let section = $('.filters-products').offset().top - 200;
    $('body,html').animate({ scrollTop: section }, 1000);
  });
  // // Scroll to ID
  // $(document).on('click', 'a[href^="#"]', function (event) {
  //   event.preventDefault();
  //   $('html, body').animate(
  //     {
  //       scrollTop: $($.attr(this, 'href')).offset().top,
  //     },
  //     500
  //   );
  // });
  // var ajaxFunction = function (data) {
  //   $.ajax({
  //     type: 'post',
  //     url: ajax_object.ajax_url,
  //     data: { action: 'get_tour', keyword: data },
  //     beforeSend: function () {
  //       $('#loading').fadeIn('slow');
  //     },
  //     success: function (data) {
  //       $('#loading').fadeOut('slow');
  //       $('.search-appear').append(data);
  //     },
  //   });
  // };
  /**
   * Search on Faq Page
   */
  if ($('.faq-page').length ? $(this).length : 0) {
    let searchInput = document.getElementById('searchInput');

    searchInput.addEventListener('keyup', function (event) {
      if (searchInput.value != '') {
        let searchQuery = event.target.value.toLowerCase();
        jQuery.expr[':'].icontains = function (a, i, m) {
          return (
            jQuery(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0
          );
        };
        let result = $('.accordion-title:icontains(' + searchQuery + ')');
        $('.result').empty();
        $('.result').show();
        result.each(function () {
          let val = $(this).text();
          let id = $(this).attr('id');
          $('.result').append('<a href="#' + id + '" >' + val + '</a>');
        });
      } else {
        $('.result').hide();
      }
    });
  }
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * Forgot Password
   */

  $('#forgot1').click(function () {
    var data = {};
    data['remember-email'] = $('#remember-email').val();
    data['action'] = 'remember_action';

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: data,

      success: function (response) {
        console.log(response);
        if (response === 'Check your email address for you new password.') {
          alert(response);
          // window.location.href = '<?php echo esc_attr( $redirect_to ); ?>'; // login redirect
        } else {
          alert(response);
        }
      },

      error: function () {
        console.log('err');
        alert('Something went wrong!');
      },
    });
  });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 170 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );
  //   Top Button
  var offset = 300;
  var speed = 350;
  var duration = 1000;
  $(window).scroll(function () {
    if ($(this).scrollTop() < offset) {
      $('.topbutton').fadeOut(duration);
    } else {
      $('.topbutton').fadeIn(duration);
    }
  });
  $('.topbutton').on('click', function () {
    $('html, body').animate({ scrollTop: 0 }, speed);
    return false;
  });
  /**
   * Show and hide filters
   */

  $('.filters-wrapper__faset > h4').each(function () {
    $(this).click(function () {
      console.log('sdfsdf');
      $(this).toggleClass('hide-list');
      $(this).next('.facetwp-type-checkboxes').slideToggle();
    });
  });
  $('.filters-wrapper__category > h4').click(function () {
    $(this).toggleClass('hide-list');
    $(this).next('.filters-wrapper__category-items').slideToggle();
  });
  $('.facet-title').each(function () {
    if ($(this).hasClass('hide-list')) {
      $(this).next('.facetwp-type-hierarchy').attr('style', 'display:none;');
    } else {
      $(this).next('.facetwp-type-hierarchy').attr('style', 'display:block;');
    }
    $(this).click(function () {
      $(this).toggleClass('hide-list');
      if ($(this).hasClass('hide-list')) {
        $(this).next('.facetwp-type-hierarchy').attr('style', 'display:none;');
      } else {
        $(this).next('.facetwp-type-hierarchy').attr('style', 'display:block;');
      }
      // $(this).next('.facetwp-type-hierarchy').slideToggle();
    });
  });

  $('.filters-show').click(function () {
    $('.popup').toggleClass('popup-show');
  });

  $('.facetwp-clear-filters').on('click', function (e) {
    e.preventDefault();
    FWP.reset();
  });
  /**
   * Sub-menu toggler
   */
  $('.submenu-toggle').click(function () {
    $(this).parent('li').toggleClass('sub-open');
  });
  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $('.menu-icon').click(function () {
    $('.top-bar').toggleClass('overflow-scroll');
    $('body').toggleClass('overflow-hidden');
  });

  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      $('.top-bar').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      $('.top-bar').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 1025) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  let stickyElement = $('.filters-wrapper__searсh');
  let stickyClass = 'sticky-filters';
  let offsetEl = stickyElement.length ? stickyElement.offset().top : 0;

  $(window).scroll(function () {
    let scroll = $(window).scrollTop();
    if (scroll >= offsetEl) {
      stickyElement.addClass(stickyClass);
    } else {
      stickyElement.removeClass(stickyClass);
    }
  });

  $(window).resize(function () {
    offsetEl = stickyElement.length ? stickyElement.offset().top : 0;
  });
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
